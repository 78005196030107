<template>
  <div v-if="isFetchingData" />
  <MobileAppLinks
    v-else-if="displayAppLinks"
    @close="closeMobileApps"
  />
  <div
    v-else
    class="join-call-wrapper"
  >
    <div class="leftColumn">
      <div class="inner">
        <div class="join-call">
          <h4>
            <span>Meeting ID:</span> {{ meetingRoom.webrtc.alias }}
          </h4>
          <h2>
            Welcome to {{ displayName }}'s
            <span v-if="(meetingRoom.brand.call_type === 'meetings') || !meetingRoom.brand.call_type">meeting</span>
            <span v-if="meetingRoom.brand.call_type === 'conferences'">conference</span>
            <span v-if="meetingRoom.brand.call_type === 'other'">{{ meetingRoom.brand.custom_call_type }}</span>
          </h2>

          <BeforeMeeting
            v-if="!isFetchingData && !showInvite"
            ref="BeforeMeeting"
            :key="BeforeMeetingKey"
            :webrtc-alias="currentWebrtcAlias"
            :preloaded-meeting-room="meetingRoom"
            :preloaded-display-name="displayName"
            :preloaded-call-type="callType"
          />

          <SendEmailInvites
            v-if="!isFetchingData && showInvite"
            :webrtc-alias="currentWebrtcAlias"
            @close-email-invites="showInvite = false"
          />
        </div>
        <OtherWaysToJoin
          :alias="webrtcAlias"
          :is-host="true"
          :clear="clearButtonFeedback"
          @show="showInvite = !showInvite"
        >
          <template #title>
            <h4><span>Invite others to this meeting</span></h4>
          </template>
        </OtherWaysToJoin>
      </div>
    </div>
    <HeroImage
      :alias="webrtcAlias"
      :highlight="false"
      :type="'xr_host'"
    />
  </div>
</template>

<script>
import CopyToClipboardMixin from '@/mixins/CopyToClipboardMixin';
import DirectLinkMixin from '@/mixins/DirectLinkMixin';
import ResponsiveMixin from '@/mixins/ResponsiveMixin';
import BeforeMeeting from '../../JoinMeeting/JoinMeeting/BeforeMeeting';
import SendEmailInvites from '@/components/SendEmailInvites.vue';
import MobileAppLinks from '@/components/call/MobileAppLinks.vue';
import OtherWaysToJoin from '@/components_v2/intelligent/__COMP_OtherWaysTojoin.vue';
import HeroImage from '@/components_v2/intelligent/branding/__COMP_HeroImage.vue';

import {callStore} from '@/store/__STORE_call';
import {authStore} from '@/store/__STORE_auth';
import {mainStore} from '@/store/__STORE_main';
import {brandingStore} from '@/store/__STORE_branding';
import {userStore} from '@/store/__STORE_user';

export default {
  name: 'WelcomeAuthorised',
  components: {
    BeforeMeeting,
    SendEmailInvites,
    MobileAppLinks,
    OtherWaysToJoin,
    HeroImage,
  },
  mixins: [
    CopyToClipboardMixin,
    DirectLinkMixin,
    ResponsiveMixin,
  ],
  props: {
    error: {
      type: String,
      default: null,
    },
    webrtcAlias: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isFetchingData: true,
      showInvite: false,
      currentWebrtcAlias: '',
      displayName: '',
      brand: {
        primary_color_hex: null,
        secondary_color_hex: null,
      },
      host: {
        name: '',
        last_name: '',
      },
      defaultBkImage: require('@/assets/img/SV-rebrand-hero.png'),
      backImageStyle: '',
      showHostPin: false,
      pin: null,
      highlightQR: false,
      BeforeMeetingKey: 0,
      showMobileApps: true,
      clearButtonFeedback: false,
      pusher: null,
      timeout: null,
    };
  },
  computed: {
    authInfo: () => userStore.__GETTERuserProfile,
    isLoggedIn: () => authStore.__GETTERisUserLoggedIn,
    meetingRoom: () => callStore.vmr,
    callType: () => callStore.callType,
    isOneTimeMeeting() {
      return this.$router.currentRoute.name === 'host-one-time-meeting';
    },
    displayAppLinks() {
      if (this.isMobileDevice()) {
        return this.showMobileApps;
      } else {
        return false;
      }
    },
    callData: () => callStore.__GETTERcallData,
  },
  watch: {
    callType() {
      this.reRenderBeforeMeeting();
    },
    showInvite() {
      if (!this.showInvite) this.clearButtonFeedback = !this.clearButtonFeedback;
    },
    callData: {
      handler(value) {
        const fullName = value?.user?.name && value?.user?.last_name ? `${value.user.name} ${value.user.last_name} ` : '';
        if (value?.status === 'reject') {
          this.$toast.error(`User ${fullName}has rejected the call`);
        } else if (value?.status === 'accept') {
          this.$toast.success(`User ${fullName}is joining the call`);
        }
      },
      deep: true,
    },
  },
  mounted() {
    userStore.getUserProfile().then(() => {
      callStore.getCallFromPhonebook({id: this.authInfo.id, isCall: false}).then((res) => this.pusher = res);
    });
    this.timeout = setTimeout(() => {
      if (!this.callData?.status && callStore.__GETTERcalledUser?.id) {
        const fullName = `${callStore.__GETTERcalledUser.name} ${callStore.__GETTERcalledUser.last_name}`;
        this.$toast.error(`User ${fullName} has missed the call`);
        callStore.__setCalledUser(null);
      }
    }, 26000);
    this.$emit('disable-fullscreen');

    if (!this.isLoggedIn) {
      this.$router.push({
        name: 'welcome-guest',
        params: {
          webrtcAlias: this.webrtcAlias,
        },
      });
    }

    this.currentWebrtcAlias = this.webrtcAlias;

    this.getData();
  },
  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },
  destroyed() {
    if (this.pusher) this.pusher.unsubscribe(`private-user.${this.authInfo.id}`);
    this.$emit('set-room-brand', null);
  },
  methods: {
    closeMobileApps() {
      this.showMobileApps = false;
    },
    async getData() {
      await callStore.getVMR(this.currentWebrtcAlias).catch((errorObject) => {
        this.onError(errorObject);
      });
      if (!this.meetingRoom.webrtc.pin) {
        this.$router.push({
          name: 'welcome-guest',
          params: {
            webrtcAlias: this.webrtcAlias,
          },
        });
      }
      mainStore.setLoading();
      this.displayName = this.meetingRoom.host.name + ' ' + this.meetingRoom.host.last_name;
      this.$emit('set-room-brand', this.meetingRoom.brand);
      this.brand = this.meetingRoom.brand;

      brandingStore.setLogo( {logo: this.brand.logo, level: this.brand.level});
      mainStore.setSuccess();
      this.isFetchingData = false;
    },
    onError(errorObject) {
      const statusCode = errorObject.response ? errorObject.response.status : 'undefined';
      const errorMsg = ('Unexpected error, code ' + statusCode);

      this.redirectBack(errorMsg);
    },
    redirectBack(errorMsg) {
      this.$router.push({
        name: 'dashboard',
        params: {
          error: errorMsg || '',
        },
      });
    },
    joinMeeting(e) {
      e.preventDefault();
      this.$refs.BeforeMeeting.joinMeeting();
    },
    reRenderBeforeMeeting() {
      this.BeforeMeetingKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";

  .join-call-wrapper {
    display: flex;
    flex-wrap: nowrap;
    min-height: 100%;

    .leftColumn {
      display: flex;
      flex: 1 0 50%;
      align-items: center;
      justify-content: center;
      padding: 10vh;

      .inner {
        display: flex;
        flex-flow: column;
        height: 100%;
        justify-content: space-between;
        width: 100%;

        h2 {
          border-bottom: 1px solid #ddd;
          font-size: 24px;
          font-weight: bold;
          margin: 10px 0 25px;
          padding: 0 0 25px;
        }

        h4 {
          font-size: 16px;
          span {
            font-weight: bold;
          }
        }

      }
    }

    .leftColumn {
      min-width: 700px;
      z-index: 2;
    }

    // .rightColumn {
    //   background: center center;
    //   background-size: contain;
    //   background-repeat: no-repeat;
    //   flex: 0 1 50%;
    //   position: relative;
    //   z-index: 1;

    //   .inner {
    //     align-items: flex-end;
    //     justify-content: flex-end;

    //     .qr-code {

    //       label {
    //         display: none;
    //         font-weight: bold;
    //       }

    //       canvas {
    //         border: 3px solid transparent;
    //       }
    //     }
    //   }
    // }
  }

  @include media-breakpoint-down(md) {
    .join-call-wrapper {
      flex-direction: column;
      height: 100%;

      .leftColumn {
        flex: 1 0 50%;
        padding: 5vh;
      }

      // .rightColumn {
      //   flex: 0 1 50%;
      // }
    }
  }

  @include media-breakpoint-down(sm) {
    .join-call-wrapper {
      display: block;
      margin-top: 78px;

      .leftColumn {
        display: block;
        min-width: 0;

        .inner {

        }
      }

      // .rightColumn {
      //   .inner {
      //     align-items: center;
      //   }
      // }
    }
  }
</style>
